<template>
  <div class="add-to-cart-wrapper">
    <CustomButton
      :type="btnType"
      :is-small="isSmall"
      :aria-attrs="{
        'aria-label': `Add ${productName} to Cart`,
        'aria-disabled': disabled,
      }"
      :disabled="disabled"
      :style-name="secondaryButton ? 'secondary' : undefined"
      style-color="black"
      @click-button="addToCartAction"
    >
      <template v-if="cartActionInProgress && state.buttonClicked"> Adding... </template>
      <template v-else>
        {{ useTranslate('GLOBALS.addToCart')
        }}<span v-if="addToCartMsgAdditional" :class="['addt-msg']">{{ addToCartMsgAdditional }}</span>
      </template>
    </CustomButton>
  </div>
</template>

<script setup lang="ts">
import { useCartStore } from '~/store/cart';
import { useLocaleStore } from '~/store/locale';
import { useRootStore } from '~/store';
import { scrollToTop, lockBody } from '~/util/eventHandler';
import { AddToCartItem } from '~/types/bigcommerce';
import { useCloudinary } from '~/composables/cloudinary';

const cartStore = useCartStore();
const localeStore = useLocaleStore();
const rootStore = useRootStore();
const route = useRoute();
const cloudinary = useCloudinary();

const emit = defineEmits(['added-to-cart']);

const props = defineProps({
  quantity: {
    type: Number,
    default: 1,
    required: false,
  },
  emitAdded: {
    type: String,
    required: false,
    default: undefined,
  },
  product: {
    type: Object as () => any,
    required: true,
  },
  images: {
    type: Array as () => any[],
    default: () => [],
  },
  variant: {
    type: Object as () => any,
    default: () => {},
  },
  addToCartMsgAdditional: {
    type: String,
    default: '',
    required: false,
  },
  isSmall: {
    type: Boolean,
    default: false,
  },
  isVariant: {
    type: Boolean,
    default: false,
  },
  disableBtn: {
    type: Boolean,
    default: false,
  },
  secondaryButton: {
    type: Boolean,
    default: false,
  },
  btnType: {
    type: String,
    required: false,
    default: undefined,
  },
  renderMode: {
    type: String,
    default: '',
  },
});

const state = reactive({
  buttonClicked: false,
});

defineExpose({
  addToCartAction,
});

const cartActionInProgress = computed(() => cartStore.cartActionInProgress);
// const gettingCart = computed(() => cartStore.gettingCart);
const cart = computed(() => cartStore.cart);

const productName = computed<string>(() => props?.product?.display_title || '');
const productId = computed<number | undefined>(() => props?.product?.id);
const disabled = computed<boolean>(
  () =>
    rootStore?.siteUnderMaintenance ||
    props.disableBtn ||
    productId.value === null ||
    (state.buttonClicked && cartActionInProgress.value) ||
    (!cart.value?.id && cartActionInProgress.value)
);

async function addToCartAction(_e: any, atcOverride = false) {
  if (props.renderMode === 'gift-card' && !atcOverride) return;
  const image = props.images?.length ? props.images : props.product?.images;
  let cldImage = '';
  if (image && image?.[0]?.public_id) cldImage = cloudinary.generateImageUrl(image?.[0]?.public_id);
  else if (props.product?.thumb) cldImage = props.product?.thumb?.[0]?.file?.url;
  else if (props.renderMode === 'gift-card' && image) cldImage = image?.[0]?.file?.url;
  let sku = '';
  if (props.renderMode === 'gift-card') sku = 'SS-GIFT-CARD';
  else sku = props?.isVariant ? props?.variant?.sku : props.product?.sku;
  const parentSku = props.isVariant ? props.product?.sku : null;

  const subData = props.product?.subData;
  // eslint-disable-next-line camelcase
  const option_selections = props.product?.option_selections;
  let giftCard;
  if (props.renderMode === 'gift-card') {
    giftCard = props.product.gift_certificates;
  }
  const cartProduct: AddToCartItem[] = [
    {
      quantity: props.quantity,
      product: {
        id: props.product.id,
        name: props.product.name,
        variantId: props.product.variantId,
        image: cldImage,
        sku,
        parentSku,
        subData,
        // eslint-disable-next-line camelcase
        option_selections,
        gift_certificates: giftCard,
      },
      hasVariants: props.isVariant,
    },
  ];
  if (!props.disableBtn) {
    state.buttonClicked = true;
    await useAddToCart({ cartItems: cartProduct });
  }
  state.buttonClicked = false;
  // Prevents cart modal on cart page. Scrolls to top on a2c action.
  // if ($route.path === '/' + localeStore.langCode + '/cart') {
  if (route.path === '/' + localeStore.langCode + '/cart') {
    scrollToTop();
    cartStore.setNewItemAdded(false);
  }
  emit('added-to-cart');
  lockBody();
}
</script>

<style lang="scss" scoped>
.add-to-cart-wrapper {
  width: 100%;
}
.addt-msg {
  margin-left: 5px;
}
:deep(.btn) {
  width: 100%;
}
#a2c-button :deep(.new-cta.btn) {
  font-size: 1.125rem;
  background-color: $color-neutral-cool-900;
  border-color: $color-neutral-cool-900;
  text-transform: none;
}
</style>
